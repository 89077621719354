.rst__node {
  height: 70px !important;
}

.rst__row {
  border-radius: 2px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  width: 450px;
}

.rst__rowTitle {
  font-weight: 300 !important;
}

.rst__rowLabel > span > input {
  width: 280px;
  border-bottom: 1px solid lightgrey;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.rst__rowLabel > span > input:focus {
  width: 280px;
  border-bottom: 1px solid #9c27b0;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}